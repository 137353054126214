<template>
   
   <div class="brand-top">
    <div class="logos contenudesk">
        <img src="../../../../../public/assets/images/meyro-logos.png" />
    </div>
    <div class="logos contenumob">
        <img src="../../../../../public/assets/images/meyro-logos2.png" />
    </div>
   
   </div>
   <div class="brand-bottom">
    <div class="bg-left">
        <img src="../../../../../public/assets/images/meyro-bg-left.png" />
    </div>
    <div class="bg-right">
        <img src="../../../../../public/assets/images/meyro-bg-right.png" />
    </div>
    <div class="content">
    <div class="title">Collection <span class="i">Louis</span></div>
        <span v-if="language=='fr'">
            Le modèle Louis Infini allie une monture en titane imprimée en 3D avec des inserts en bio-plastique et des branches en métal. Avec ses nombreuses possibilités de personnalisation, ce modèle représente une structure complexe rendue simple grace à une precision ultime pour un ajustement parfait.
          </span>
        <span v-else>
            The Louis Infinite model combines a titanium 3D-printed frame with bioplastic inserts and metal temples. With its many different customisation possibilities, this model represents a complex structure made simple with ultimate precision, for the ultimate fit.
      
           </span>
    
</div></div>

</template>

<script>

export default {
    name: "BrandTopComponent",
    props: ["language"],
    components: {
       
  },
    methods: {    

        

                
    },
    data(){
      
        return{
          

        }
    },
    computed: {
    

    },
}
</script>

<style scoped>
    @media screen and (max-width:760px)
    {
        .brand-bottom .content
        {
            width:100% !important;
        }
        .brand-bottom
        {
            padding-top:50px !important;
            padding-bottom:50px !important;
            padding-left:20px !important;
            padding-right:20px !important;
        }
        .brand-top .logos
        {
            top:10% !important;
            left:50% !important;
            transform: translateX(-50%);
        }
        .brand-top
        {
            background-image:url('../../../../../public/assets/images/meyro-top-mob.jpg') !important;
        }
        
    }
    @media screen and (min-width:760px) and (max-width:1024px)
    {
        .brand-bottom .content
        {
            width:80% !important;
        }
        .brand-top .logos.contenudesk
        {
            display: none !important;
        }.brand-top .logos.contenumob
        {
            display: block !important;
        }
        .brand-top .logos
        {
            top:auto !important;
            bottom:5% !important;
        }
        .brand-top
        {
            background-image:url('../../../../../public/assets/images/meyro-top-ipad.jpg') !important;
        }
        
    }
    .compo-logo
    {
        white-space: nowrap;
        text-align: center;
        position:relative;

    }
    .compo-logo .line1
    {
        font-size:20px;
        width:100%;
        line-height:0.5;
        justify-content: space-between;
        font-family: 'ProFontWindows';
        display: flex;
    }
    .compo-logo .line2
    {
        line-height:0.9;
        font-weight: 300;
        font-size:55px;
        display: flex;
    }
    .compo-logo .line4
    {
        line-height:2;
        justify-content: space-between;
        font-weight: 300;
        font-size:16px;
        width:100%;
        display: flex;
    }
    .compo-logo .line3
    {
        font-weight: 500;

        line-height:0.8;
        font-size:65px;
        display: flex;
    }
    .compo-logo .part1,
    .compo-logo .part2
    {
        display:flex;
        align-items: center;
        flex-direction: column;
    }
    .brand-top .logos
    {
        position:absolute;
        left:5%;
        top:60%;
        max-width:50%;
        
    }
    .brand-bottom .bg-right img
    {
        height:100%;
        width:auto;
    }
    .brand-bottom .bg-left img
    {
        height:100%;
        width:auto;
    }
    .brand-bottom .bg-right
    {
        position: absolute;
        right:0;
        height:100%;

    }
    .brand-bottom .bg-left
    {
        position: absolute;
        left:0;
        height:100%;

    }
    .brand-bottom .left
    {
        width:40%;
        max-width: 315px;
    }
    .brand-bottom .right
    {
        font-size:18px;
        width:60%;
        padding-left:50px;
        max-width: 545px;
    }
    .brand-bottom
    {
        
        justify-content: center;
        align-items: center;
        display:flex;
        position:relative;
        color:#fff;
        bottom:0;
        left:0;
        width:100%;
        padding:80px;
        background: rgba(117, 110, 93, 1);
        
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
    }
    .brand-top
    {
        min-height:100vh;
        width:100%;
        background-position: center center;
        position:relative;
        background-size:cover;
        background-image:url('../../../../../public/assets/images/meyro-top.jpg');
    }
    .brand-bottom .title {
        font-size:38px;
        margin-bottom:20px;
    }
    .brand-bottom .title .i 
    {
        font-weight: 600;
        font-style: italic;
    }
    .brand-bottom .content
    {
        font-size:18px;
        margin-left:auto;
        margin-right:auto;
        width:50%;
        text-align: center;
        max-width: 550px;
    }
</style>