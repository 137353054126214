<template>
   
  <div class="brand-production">

    <div class="content">
      <img class="zero" src="../../../../../public/assets/images/zero.png"  />
      <div class="left">
        Production Zéro <span class="i">Déchet</span>
      </div>
      <div class="middle">
        NOTRE COLLABORATION A ÉTÉ PENSÉE ÉCO-FRIENDLY, CHERCHANT À REMÉDIER À NOTRE ÉCHELLE À LA PROBLÉMATIQUE DES DÉCHETS PLASTIQUES ET À LA PROUESSE DU ZÉRO DÉCHET.
      </div>
      <div class="right">
        La technologie additive est un procédé par nature écoresponsable, en circuit fermé, qui s’emploie à l’utilisation d’un seul et unique matériau afin de n’engendrer aucun surplus de production. Notre ligne entrepreneuriale épouse parfaitement la mouvance en vogue du slow design et du design « to be re-used ». Notre lunette collaborative a été pensée technique, créative et au service de l’écologie, afin d’en faire un produit de son temps mais surtout d’avenir.
      </div>
      
    </div>

  </div>

</template>

<script>

export default {
    name: "BrandProductionComponent",
    props: ["language"],
    components: {
       
  },
    methods: {    

        

                
    },
    data(){
      
        return{
          

        }
    },
    computed: {
    

    },
}
</script>

<style scoped>
@media screen and (max-width:760px)
{
  .brand-production
  {
    min-height:80vh !important;
    background-size: auto 50% !important;
    background-position: left top !important;
    background-repeat: no-repeat;
  }
  .brand-production .content .middle
  {
    padding-left: 0 !important;
    padding-right:0 !important;
    margin-top:20px;
    margin-bottom:20px;
  }
  .brand-production .content .left
  {
    text-align: left !important;
    font-size:32px !important;
  }
  .brand-production .content .left,
  .brand-production .content .right,
  .brand-production .content .middle
  {
    width:100% !important;
  }
  .zero 
  {
    display:none !important;
  }
  .brand-production .content 
  {
    background:#000 !important;
    padding-bottom:30px !important;
    padding-top:30px !important;
    flex-direction: column;
    padding-left:20px !important;
    padding-right:20px !important; 
  }
}

@media screen and (min-width:760px)  and (max-width:1024px)
{
  .brand-production
  {
    min-height:50vh !important;
    background-size: 100% auto !important;
    background-position: left top !important;
    background-repeat: no-repeat;
  }
  .brand-production .content .middle
  {
    padding-left: 0 !important;
    padding-right:0 !important;
    margin-top:20px;
    margin-bottom:20px;
  }
  .brand-production .content .left
  {
    text-align: center !important;
    font-size:42px !important;
  }
  .brand-production .content .right
  {
    margin-top:20px;
  }
  .brand-production .content .right,
  .brand-production .content .middle
  {
    display:inline-block !important;
    width:50% !important;
    vertical-align: top;
    
  }
  .brand-production .content .left
  {
    width:100% !important;
  }
  .zero 
  {
    display:none !important;
  }
  .brand-production .content 
  {
    background:#000 !important;
    padding-bottom:30px !important;
    padding-top:30px !important;
    flex-direction: column;
    display:block !important;
    padding-left:20px !important;
    padding-right:20px !important; 
  }
}
.zero
{
  animation: rotating 6s linear infinite;
    position:absolute;
    width:150px;
    bottom:50px;
    filter: brightness(0) invert(1);
}
.brand-production .content .left .i
{
  font-weight: 600;
  font-style: italic;
}
.brand-production .content .middle
{
  width:37%;
  font-size:16px;
  font-weight: 600;
  padding-left: 50px;
  padding-right:50px;
}
.brand-production .content .right
{
  width:26%;
  font-size:16px;
}
.brand-production .content .left
{
  font-size:62px;
  line-height: 0.9;
  width:37%;
  text-align: right;
  }
  
.brand-production .content 
  {
    position:absolute;
    bottom:0;
    padding-left:100px;
    padding-right:100px;
    padding-top:100px;
    padding-bottom:100px;
    left:0;
    width:100%;
    display: flex;
    color:#fff;
    
    background: rgba(0, 0, 0,0.8) 0% 0% no-repeat padding-box;
   
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
  }
  .brand-production
  {
   
    width:100%;
    position:relative;
   
    min-height:100vh;

    background-size:cover;
    background-position: center center;
    background-image: url('../../../../../public/assets/images/reveur-zero.jpg');
  }

</style>