<template>
  <div class="page-template-home-closed-new">

        
        <BrandTopComponent v-if="brandName=='reveurlucide'" :language="language"/>
        <BrandProductsComponent  v-if="brandName=='reveurlucide'"  :language="language"/>
        <BrandBigComponent v-if="brandName=='reveurlucide'"   :language="language"/>
        <BrandProductionComponent  v-if="brandName=='reveurlucide'"  :language="language"/>
        <BrandBottomComponent v-if="brandName=='reveurlucide'"   :language="language"/>

        <BrandTopComponentMeyro v-if="brandName=='meyrowitz'" :language="language"/>
        <BrandProductsComponentMeyro  v-if="brandName=='meyrowitz'"  :language="language"/>
        <BrandBigComponentMeyro v-if="brandName=='meyrowitz'"   :language="language"/>
        <BrandBottomComponentMeyro v-if="brandName=='meyrowitz'"   :language="language"/>
        <BrandProductionComponentMeyro  v-if="brandName=='meyrowitz'"  :language="language"/>
      

    <br /><br /><br /><br />
    <Footer :language="language" :displayModalMenu="displayModalMenu" />
 
  </div>
</template>

<script>
import Footer from '../components/layout/Footer.vue'

import {APIRequest} from "@simb2s/senseye-sdk"
import { ContentLoader } from 'vue-content-loader'
// Import children
import BrandBottomComponent from '../components/children/Brand/Reveur/BrandBottomComponent.vue';
import BrandProductionComponent from '../components/children/Brand/Reveur/BrandProductionComponent.vue';
import BrandBigComponent from '../components/children/Brand/Reveur/BrandBigComponent.vue';
import BrandTopComponent from '../components/children/Brand/Reveur/BrandTopComponent.vue';
import BrandProductsComponent from '../components/children/Brand/Reveur/BrandProductsComponent.vue';

import BrandBottomComponentMeyro from '../components/children/Brand/Meyro/BrandBottomComponent.vue';
import BrandProductionComponentMeyro from '../components/children/Brand/Meyro/BrandProductionComponent.vue';
import BrandBigComponentMeyro from '../components/children/Brand/Meyro/BrandBigComponent.vue';
import BrandTopComponentMeyro from '../components/children/Brand/Meyro/BrandTopComponent.vue';
import BrandProductsComponentMeyro from '../components/children/Brand/Meyro/BrandProductsComponent.vue';

import { useSettingsStore } from '@/store/SettingsStore.js';
import { useMeta } from 'vue-meta'

export default {
    name: 'Collection',
    components: {
        Footer,
 
        ContentLoader,
        BrandProductsComponent,
        BrandTopComponent,
        BrandBigComponent,
        BrandProductionComponent,
        BrandBottomComponent,
        BrandProductsComponentMeyro,
        BrandTopComponentMeyro,
        BrandBigComponentMeyro,
        BrandProductionComponentMeyro,
        BrandBottomComponentMeyro
       
    },
    data() {
        return {
            useSettings:useSettingsStore(),
            loaded:false,
            brandName:this.$route.params.brand
        }
    },
    props:["displayModalMenu","language"],
    computed:{

       
    },
    
    watch: {
        
           
    },
    methods:{
        
    },
    created() {
        if(this.language=='fr')
        {

            useMeta({
    title: "VISAGES - Collection optique et solaire",
    
    htmlAttrs: { lang: this.language, amp: true },

})
    

           
            document.querySelector('meta[name="description"]').setAttribute('content',"Chaque monture de notre Collection optique et solaire est personnalisable. Toutes nos paires de lunettes sont produites à l'unité par impression 3D, en harmonie parfaite avec la morphologie de votre visage.")
        }
        else
        {

            useMeta({
    title: "VISAGES - Optic and solar collection",
    
    htmlAttrs: { lang: this.language, amp: true },

})
            
            document.querySelector('meta[name="description"]').setAttribute('content',"Every frame in our Collection is customisable. Visages ensures that each pair of glasses is unique and 3D printed on-demand, in perfect harmony with the contours of your face.")
        }
    },

    async mounted()
    {

        console.log('OK')
        console.log(this.brandName)
        
        if(this.useSettings.settingsLoaded==false) {
            await this.useSettings.loadSettings()
        }
        

        if(this.$route.params.lang)
        {
        this.$emit('changeLanguageEv', this.$route.params.lang)
        
        }

        this.loaded=true
    }
}
</script>

<style>
</style>