<template>
   
   <div class="collection element-big" >

            <div class="content" v-if="language=='fr'">
                <div class="title">Expertise du <span class="italic">Sur-Mesure</span></div>
                <div class="col intro">
                    Fort d'un héritage de plus de 150 ans, EB Meyrowitz se distingue comme une référence internationale dans le domaine du sur-mesure lunettier, renommé pour ses nombreuses créations à destination d'une clientèle très exclusive, comprenant des célébrités d'Hollywood jusqu'à des personnalités politiques iconiques.
                </div>
                <div class="col text">
                    Cette institution s'associe désormais à VISAGES® pour créer la collection LOUIS – un hommage à l'héritage de Meyrowitz fusionné à l'approche révolutionnaire de VISAGES® en matière d'innovation lunetière.
<br /><br />
À travers une série limitée, chaque pièce est une expression singulière de tradition, luxe et précision, symbolisant un nouveau chapitre de l'artisanat d'exception.
                </div>
            </div>

            <div class="content" v-else>
                <div class="title">Custom-fit <span class="italic">Expertise</span></div>
                <div class="col intro">
                    With its storied 150-year history, EB Meyrowitz is an international reference in bespoke eyewear craftsmanship, renowned for tailoring products to an exclusive clientele that ranges from Hollywood celebrities to iconic political figures. 

                </div>
                <div class="col text">
                    In a special collaboration, EB Meyrowitz unites with VISAGES® to create the meticulously curated LOUIS collection. This partnership stands as a tribute to Meyrowitz’s enduring heritage, merged with VISAGES®’s innovative approach to modern craftsmanship.
                    <br /><br />Together, presenting a limited series where each piece is a unique narrative of luxury, precision, and sustainable elegance.
                </div>
            </div>

        </div>

</template>

<script>

export default {
    name: "BrandBigComponent",
    props: ["language"],
    components: {
       
  },
    methods: {    

        

                
    },
    data(){
      
        return{
          

        }
    },
    computed: {
    

    },
}
</script>

<style scoped>

  @media screen and (max-width:760px)
  {

    .element-big .content {
        flex-direction: column !important;
        position:relative !important;
        top:189px;
        
    }
    .element-big .content .col {
        width:100% !important;
    }
    .element-big .content .title {
        width:100% !important;
    }
    
    .element-big{
      background-image:url('../../../../../public/assets/images/meyro-big-mob.jpg');
        min-height:100px !important;
        background-repeat: no-repeat !important;
        background-size: 100% auto !important;
    background-position: center top !important;
    margin-bottom: 250px !important;
        margin-top:0 !important;
    }
  }
  @media screen and (min-width:760px) and (max-width:1024px)
{
  .element-big{
      min-height: 55vh !important;
      background-image:url('../../../../../public/assets/images/meyro-big-ipad.jpg');
  }
}

.element-big {
    min-height:100vh;
    position:relative;
    width:100%;
    background-image:url('../../../../../public/assets/images/meyro-big.jpg');
    background-size:cover;
    background-position:center center;

}
.element-big .content .col.intro {
    font-weight: 600;
    text-transform: uppercase;
}
.element-big .content .col {
    font-size:15px;padding:20px;
}
.element-big .content .col {
    width:30%;
}
.element-big .content .title .italic
{
    font-weight: 600;
    font-style: italic;
    
}
.element-big .content .title {
    padding:10px;
    font-size:60px;
    width:40%;
    line-height: 0.8;
}
.element-big .content {

    background: rgba(117, 110, 93, 0.8) 0% 0% no-repeat padding-box;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    color:#fff;
    padding:5%;
    display:flex;
    position:absolute;
    bottom:0;
    width:100%;
    left:0;
}
 

</style>