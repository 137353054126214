<template>
   
   <div class="collection element-two">
            <div class="top">
                <div class="left image">
                </div>
                <div class="right content">
                    
                    <span v-if="language=='fr'">Titane <br /><span class="italic">Imprimé en 3D</span></span>
                        <span v-else>3D-Printed<br /><span class="italic">Titanium</span></span>
                  
                   
                </div>

            </div>
            <div class="bottom">
                <div class="left content">
                    

                    
                    <span v-if="language=='fr'">
                            <span class="italic">& Inserts</span><br />en Bio-Plastique<br />
                        </span>
                        <span v-else>

                              


                            <span class="italic">& Bio-Plastic</span><br />Inserts<br />
                        </span>
                        <div v-if="language=='fr'" class="round-element">Finition Patine</div>
                        <div v-else class="round-element">Patinated finish</div>
                        <div  v-if="language=='fr'" class="round-element">Système de Charnières Sans Vis</div>
                        <div  v-else class="round-element">Proprietary Screw-Less Hinge System</div>

                       

                </div>
                <div class="right image" >
                </div>

            </div>
        </div>

</template>

<script>

export default {
    name: "BrandBottomComponent",
    props: ["language"],
    components: {
       
  },
    methods: {    

        

                
    },
    data(){
      
        return{
          

        }
    },
    computed: {
    

    },
}
</script>

<style scoped>

  @media screen and (max-width:760px)
  {.round-element {
    font-size: 12px !important;
    margin-top: 7px !important;
}
    .element-two .top,
    .element-two .bottom {
        position: relative;
    }
    .element-two .image.right {
        margin-left:15%;
    }
    .element-two .image {
        width:60% !important;
        height:180px !important;
        position: absolute !important;
    }
   
    .element-two .content.left {
        margin-left:0 !important;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end
    }
    .element-two .content.right {
        margin-left:35%;
        text-align: left !important;
    }
    .element-two .content {
        width:65% !important;
        font-size:25px !important;
        height:300px !important;
    }
      
  }
  @media screen and (min-width:760px) and (max-width:1024px)
  {
   
            
  }
  .round-element:hover
{
    background: rgba(117, 110, 93, 1);
    color:#fff;
}


.round-element {
    margin-top:10px;
    border: 1px solid rgba(117, 110, 93, 1);
border-radius: 34px;
font-size:25px;
width:auto;
transition: all 0.3s;
line-height: 1;
display:inline-block;
clear: both;
padding:0px 15px 4px 15px;
}
  .element-two {
    margin-top:100px;
  }
  .element-two .left.image {
    background-image:url('../../../../../public/assets/images/meyro-left.jpg'); 
}

.element-two .right.image {
    background-image:url('../../../../../public/assets/images/meyro-right.jpg'); 
}
.element-two .content .text
{
  font-size:16px;
  line-height: 1.3;
}
.element-two .content .bold
{
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 15px;
  font-size:16px;
}
.element-two .image {
  
    background-size:cover;
    background-position: center center;
    height:500px;
    width:55%;
}
.element-two .content .italic {
    font-weight: 600;
    font-style: italic;
}
.element-two .content 
{
    text-align:right;
    height:400px;
    width:40%;
}
.element-two .top {
    align-items: flex-end;
}
.element-two .left.content .round-element {
    border:1px solid #fff;
    text-transform: uppercase;
    font-size:18px;
    margin-top:0;
}
.element-two .left.content 
{
    margin-left:5%;
    text-align:left;
}
.element-two .right.content 
{
    margin-right:5%;
    text-align:right;
}
.element-two .top,
.element-two .bottom {
    display:flex;
    padding-left:10%;
    padding-right:10%;
}

.element-two .content {
    color:#fff;
    font-size:60px;
    padding:5%;
    line-height: 0.8;

    background:rgba(117, 110, 93, 1);
}
  
</style>