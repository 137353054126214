<template>
   
  <div class="brand-big">

    <div class="content">Des lunettes <span class="i">imprimées en 3D</span></div>

  </div>

</template>

<script>

export default {
    name: "BrandBigComponent",
    props: ["language"],
    components: {
       
  },
    methods: {    

        

                
    },
    data(){
      
        return{
          

        }
    },
    computed: {
    

    },
}
</script>

<style scoped>
  @media screen and (min-width:1024px)
  {

    .brand-big
  {
   
    background-image: url('../../../../../public/assets/images/reveur-big.jpg');
  }


@keyframes mymove {
    0% { background-image: url('../../../../../public/assets/images/reveur-big.jpg'); }
  25% { background-color:rgba(0, 0, 0, 0.5); }
  26% {     background-image: url('../../../../../public/assets/images/rlbig2.jpg'); }
  50% {     background-image: url('../../../../../public/assets/images/rlbig2.jpg'); }
  75% { background-color:rgba(0, 0, 0, 1); }
  76% { background-image: url('../../../../../public/assets/images/reveur-big.jpg'); }
}
  }





  @media screen and (max-width:760px)
  {

    @keyframes mymove {
    0% { background-image: url('../../../../../public/assets/images/reveur-big-mob.jpg'); }
  25% { background-color:rgba(0, 0, 0, 0.5); }
  26% {     background-image: url('../../../../../public/assets/images/rlbig2mob.jpg'); }
  50% {     background-image: url('../../../../../public/assets/images/rlbig2mob.jpg'); }
  75% { background-color:rgba(0, 0, 0, 1); }
  76% { background-image: url('../../../../../public/assets/images/reveur-big-mob.jpg'); }
}

    .brand-big .content
    {
      font-size:32px !important;
      padding-left:10%;
      padding-right:10%;
    }
    .brand-big
    {
     
      height:70vh !important;
      background-image: url('../../../../../public/assets/images/reveur-big-mob.jpg');
    }
      
  }
  @media screen and (min-width:760px) and (max-width:1024px)
  {

    @keyframes mymove {
    0% { background-image: url('../../../../../public/assets/images/reveur-big-ipad.jpg'); }
  25% { background-color:rgba(0, 0, 0, 0.5); }
  26% {     background-image: url('../../../../../public/assets/images/rlbig2ipad.jpg'); }
  50% {     background-image: url('../../../../../public/assets/images/rlbig2ipad.jpg'); }
  75% { background-color:rgba(0, 0, 0, 1); }
  76% { background-image: url('../../../../../public/assets/images/reveur-big-ipad.jpg'); }
}


    .brand-big .content
    {
      font-size:42px !important;
      padding-left:10%;
      padding-right:10%;
    }

    .brand-big
    {
     
      height:50vh !important;
      background-image: url('../../../../../public/assets/images/reveur-big-ipad.jpg');
    }
            
  }
  .brand-big .content .i
  {
    font-weight: 600;
    font-style: italic;
  }
  .brand-big .content
  {
    color:#fff;
    position:absolute;
    left:50%;
    top:50%;
    width:100%;
    transform: translate(-50%,-50%);
    text-align: center;
    font-size:62px;
  }
  .brand-big
  {
    margin-top:100px;
    margin-bottom:100px;
    width:90%;
    position:relative;
    left:5%;
    height:80vh;

    animation: mymove 10s linear infinite;
    

    background-size:cover;
    background-position: center center;
   
  }

</style>