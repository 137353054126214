<template>
   
  <div class="brand-products">

    <div class="left">
      
      <a @click="$router.push({ name: 'collection-glass-color-type', params: { id: 30, type:'solar' } })">
        <img src="../../../../../public/assets/images/reveur-model1.png" />

      </a>
      <div class="product-bottom">
        <div class="product-title">RL2</div>
        <div class="product-price"><span class="from">À partir de </span> 500€</div>
      </div>
    </div>
    <div class="right">
      <a @click="$router.push({ name: 'collaboration-glass', params: { id: 24 } })">
        <img src="../../../../../public/assets/images/reveur-model2.png" />
        <img class="photo-hover" src="../../../../../public/assets/images/reveur-model2-2.png" />
        </a>

        <div class="product-bottom">
        <div class="product-title">Tête en l'air</div>
        <div class="product-price"><span class="from">À partir de </span> 500€</div>
      </div>

    </div>

  </div>

</template>

<script>

export default {
    name: "BrandProductsComponent",
    props: ["language"],
    components: {
       
  },
    methods: {    

        

                
    },
    data(){
      
        return{
          

        }
    },
    computed: {
    

    },
}
</script>

<style scoped>
.product-bottom
{
  position: absolute;
  bottom:-30px;
  width:100%;
}
.product-price
{
  float:right;
  display: inline-block;
  font-size: 21px !important;
    font-weight: 600;
}
.from
{
  font-size: 13px;
    color: #ACACAC;
    font-weight: 400;
}
.product-title
{
  display: inline-block;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
}
.brand-products a:hover .photo-hover
{
  opacity:1;
}
.brand-products a .photo-hover
{

  opacity:0;
  transition:all 0.3s;
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:auto;
}
.brand-products a 
{
  cursor: pointer;
}
@media screen and (max-width:760px)
{
  .brand-products .left
  {
    margin-bottom:60px;
  }
  .brand-products .left,
  .brand-products .right{
    width:100% !important;
    min-height: 100vw;
  }
  
  
  .brand-products
  {
    flex-direction: column;
  }
}
.brand-products .left img
{
    position:absolute;
    top:50%;
    transform: translateY(-50%);
}
.brand-products .left,
.brand-products .right
{
    position:relative;
    width:49%;
    background:rgba(248, 248, 248, 1);
}
   .brand-products
   {
    align-items: stretch;
    width:100%;
    display:flex;
    justify-content: space-between;

   }

</style>