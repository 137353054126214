<template>
   
   <div class="brand-top">
    <div class="logos contenudesk">
        <img src="../../../../../public/assets/images/reveur-logos.png" />
    </div>
    <div class="logos contenumob">
        <img src="../../../../../public/assets/images/reveur-logos2.png" />
    </div>
    <div class="bottom">
        <div class="left">
            <div class="compo-logo">
                <div>
                <div class="part1">
                    <div class="line1">
                        <span>RÊVEUR</span>
                        <span>LUCIDE</span>
                    </div>
                    <div class="line2">
                        COLLECTION
                    </div>
                </div>
            </div>
                <div class="part2">
                    <div class="line3">
                        RÊVEUR LUCIDE
                    </div>
                    <div class="line4">
                        <span>X</span>
                        <span>VISAGES</span>
                        <span>&nbsp;</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="right">
            La lunette collaborative « Tête en L'air » est one-size, unisexe et numérotée ; une création produite à 2000 exemplaires qui en fait une édition limitée. Ce projet commun a vu le jour grâce à l'impression 3D. La technologie de frittage laser donne à cette lunette un aspect minéral avec une texture organique rehaussée par des lignes anguleuses la dimensionnant entièrement dans une esthétique contemporaine, presque futuriste.
        </div>
    </div>
   </div>

</template>

<script>

export default {
    name: "BrandTopComponent",
    props: ["language"],
    components: {
       
  },
    methods: {    

        

                
    },
    data(){
      
        return{
          

        }
    },
    computed: {
    

    },
}
</script>

<style scoped>
    @media screen and (max-width:760px)
    {
        .compo-logo .line3
  {

    font-size:56px !important;
  }
        .brand-top .logos
        {
            bottom:48% !important;
            top:auto !important;
        }
        .brand-top .bottom .left,
        .brand-top .bottom .right
        {
            width:100% !important;
        }
        .brand-top .bottom .right
        {
            padding-left:0 !important;
            margin-top:20px;
        }
        .brand-top .bottom {
            flex-direction: column;
        }

        .brand-top
        {
            min-height:1200px !important;
 
            background-image:url('../../../../../public/assets/images/reveur-top-mob.jpg') !important;
        }
        
    }
    @media screen and (min-width:760px) and (max-width:1024px) and (orientation: landscape)
    {
        .brand-top .logos
        {
            bottom:45% !important;
            top:auto !important;
            max-width: 40% !important;
        }
    }
    @media screen and (min-width:760px) and (max-width:1024px) and (orientation: portrait)
    {

        .brand-top .logos
        {
            bottom:32% !important;
            top:auto !important;
            max-width: 40% !important;
        }
    }
    @media screen and (min-width:760px) and (max-width:1024px)
    {
        .brand-top
        {
            background-image:url('../../../../../public/assets/images/reveur-top-ipad.jpg') !important;
        }

        .brand-top .logos.contenudesk
        {
            display: none !important;
        }.brand-top .logos.contenumob
        {
            display: block !important;
        }

        .brand-top .bottom .left,
        .brand-top .bottom .right
        {
            width:100% !important;
        }
        .brand-top .bottom .right
        {
            padding-left:0 !important;
            margin-top:20px;
        }
        .brand-top .bottom {
            flex-direction: column;
        }
        
    }
    .compo-logo
    {
        white-space: nowrap;
        text-align: center;
        position:relative;

    }
    .compo-logo .line1
    {
        font-size:20px;
        width:100%;
        line-height:0.5;
        justify-content: space-between;
        font-family: 'ProFontWindows';
        display: flex;
    }
    .compo-logo .line2
    {
        line-height:0.9;
        font-weight: 300;
        font-size:55px;
        display: flex;
    }
    .compo-logo .line4
    {
        line-height:2;
        justify-content: space-between;
        font-weight: 300;
        font-size:16px;
        width:100%;
        display: flex;
    }
    .compo-logo .line3
    {
        font-weight: 500;

        line-height:0.8;
        font-size:65px;
        display: flex;
    }
    .compo-logo .part1,
    .compo-logo .part2
    {
        display:flex;
        align-items: center;
        flex-direction: column;
    }
    .brand-top .logos
    {
        position:absolute;
        left:5%;
        top:35%;
        max-width:50%;
        
    }
    .brand-top .bottom .left
    {
        width:40%;
        max-width: 415px;
    }
    .brand-top .bottom .right
    {
        font-size:18px;
        width:60%;
        padding-left:50px;
        max-width: 545px;
    }
    .brand-top .bottom
    {
        
        justify-content: center;
        align-items: center;
        display:flex;
        position:absolute;
        color:#fff;
        bottom:0;
        left:0;
        width:100%;
        padding:50px;
        background: rgba(0, 0, 0, 0.8) 0% 0% no-repeat padding-box;
        
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
    }
    .brand-top
    {
        min-height:125vh;
        width:100%;
        background-position: center center;
        position:relative;
        background-size:cover;
        background-image:url('../../../../../public/assets/images/reveur-top.jpg');
    }

</style>