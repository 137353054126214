<template>
   
  <div class="brand-products">
    
    <div class="left">
      <a @click="$router.push({ name: 'collection-glass', params: { id: 27 } })">
        <img src="../../../../../public/assets/images/meyro-model1.png" />
      </a>
      <div class="product-bottom">
        <div class="product-title">Louis C</div>
        <div class="product-price"><span class="from">À partir de </span> 590€</div>
      </div>
    </div>
    <div class="right">
      <a @click="$router.push({ name: 'collection-glass', params: { id: 28 } })">
        <img src="../../../../../public/assets/images/meyro-model2.png" />
      </a>
      <div class="product-bottom">
        <div class="product-title">Louis S</div>
        <div class="product-price"><span class="from">À partir de </span> 590€</div>
      </div>
    </div>

  </div>
 
  <div class="brand-products">
    
    <div class="left">
      <a @click="$router.push({ name: 'collection-glass', params: { id: 25 } })">
        <img src="../../../../../public/assets/images/meyro-model3.png" />
      </a>
      <div class="product-bottom">
        <div class="product-title">Louis Infini</div>
        <div class="product-price"><span class="from">À partir de </span> 1700€</div>
      </div>
    </div>
    <div class="right">
      <a @click="$router.push({ name: 'collection-glass', params: { id: 26 } })">
        <img src="../../../../../public/assets/images/meyro-model4.png" />
      </a>
      <div class="product-bottom">
        <div class="product-title">Louis OP</div>
        <div class="product-price"><span class="from">À partir de </span> 3990€</div>
      </div>
    </div>

  </div>

</template>

<script>

export default {
    name: "BrandProductsComponent",
    props: ["language"],
    components: {
       
  },
    methods: {    

        

                
    },
    data(){
      
        return{
          

        }
    },
    computed: {
    

    },
}
</script>

<style scoped>
.product-bottom
{
  position: absolute;
  bottom:-30px;
  width:100%;
}
.product-price
{
  float:right;
  display: inline-block;
  font-size: 21px !important;
    font-weight: 600;
}
.from
{
  font-size: 13px;
    color: #ACACAC;
    font-weight: 400;
}
.product-title
{
  display: inline-block;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
}
.brand-products a:hover .photo-hover
{
  opacity:1;
}
.brand-products a .photo-hover
{

  opacity:0;
  transition:all 0.3s;
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:auto;
}
.brand-products a 
{
  cursor: pointer;
}
@media screen and (max-width:760px)
{
  .brand-products .left,
  .brand-products .right
  {
    min-height:100vw !important;
    width:100% !important;
  }
  .brand-products .right
  {
    margin-top:40px;
  }
  .brand-products {
   
    flex-direction: column;
  }
 
}
@media screen and (min-width:760px) and (max-width:1024px)
  {
  }
.brand-products .left img
{
    position:absolute;
    top:50%;
    transform: translateY(-50%);
}
.brand-products .left,
.brand-products .right
{
    position:relative;
    width:49%;
    background:rgba(248, 248, 248, 1);
}
   .brand-products
   {
    margin-bottom:60px;
    align-items: stretch;
    width:100%;
    display:flex;
    justify-content: space-between;

   }

</style>