<template>
   
   <div class="collection element-two">
            <div class="top">
                <div class="left image">
                </div>
                <div class="right content">
                    
                        <span v-if="language=='fr'">Design & Fabrication<br /><span class="italic">Française</span></span>
                        <span v-else>3Design & Fabrication<br /><span class="italic">Française</span></span>
                  
                   
                </div>

            </div>
            <div class="bottom">
                <div class="left content">
                    

                    
                       
                        <div  v-if="language=='fr'">
                          <div class="bold">
                            NOTRE PROCESSUS CRÉATIF EST CERTIFIÉ ENTIÈREMENT FRANÇAIS ET DE QUALITÉ, DANS TOUTES SES ÉTAPES DE RÉALISATION, GRÂCE AUX DIVERS PALIERS DE CONTRÔLE MIS EN PLACE LORS DE LA PRODUCTION.
                          </div>
                          <div class="text">
                            L’emploi de la technologie additive permet de faire bénéficier des avantages de l’impression 3D. Les matériaux PA12 présentent des caractéristiques novatrices, telles que leur résistance et légèreté hors pair.
                          </div>
                        
                        </div>

                        <div v-else>
                          <div class="bold">
                            NOTRE PROCESSUS CRÉATIF EST CERTIFIÉ ENTIÈREMENT FRANÇAIS ET DE QUALITÉ, DANS TOUTES SES ÉTAPES DE RÉALISATION, GRÂCE AUX DIVERS PALIERS DE CONTRÔLE MIS EN PLACE LORS DE LA PRODUCTION.
                          </div>
                          <div class="text">
                            L’emploi de la technologie additive permet de faire bénéficier des avantages de l’impression 3D. Les matériaux PA12 présentent des caractéristiques novatrices, telles que leur résistance et légèreté hors pair.
                          </div>
                        
                        </div>



                </div>
                <div class="right image" >
                </div>

            </div>
        </div>

</template>

<script>

export default {
    name: "BrandBottomComponent",
    props: ["language"],
    components: {
       
  },
    methods: {    

        

                
    },
    data(){
      
        return{
          

        }
    },
    computed: {
    

    },
}
</script>

<style scoped>

  @media screen and (max-width:760px)
  {
    .element-two .top,
    .element-two .bottom {
        position: relative;
    }
    .element-two .image.right {
        margin-left:15%;
    }
    .element-two .image {
        width:60% !important;
        height:180px !important;
        position: absolute !important;
    }
   
    .element-two .content.left {
        margin-left:0 !important;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        margin-top:130px;
        width:100% !important;
    }
    .element-two .content.right {
        margin-left:35%;
        text-align: left !important;
    }
    .element-two .content {
        width:65% !important;
        font-size:25px !important;
        height:300px !important;
    }
      
  }
  @media screen and (min-width:760px) and (max-width:1024px)
  {
   
    .element-two .top,
    .element-two .bottom {
        position: relative;
    }
    .element-two .image.right {
        margin-left:15%;
        position: absolute;
        width:45%;
        right:10% !important;
    }
   
   
    .element-two .content.left {
        margin-left:5% !important;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        margin-top:0;
        padding-top: 320px;
        width:90% !important;
        height:auto !important;
        position:relative;
    }
    
     
  }

  .element-two {
    margin-top:100px;
  }
  .element-two .left.image {
    background-image:url('../../../../../public/assets/images/reveur-left.jpg'); 
}

.element-two .right.image {
    background-image:url('../../../../../public/assets/images/reveur-right.jpg'); 
}
.element-two .content .text
{
  font-size:16px;
  line-height: 1.3;
}
.element-two .content .bold
{
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 15px;
  font-size:16px;
}
.element-two .image {
  
    background-size:cover;
    background-position: center center;
    height:500px;
    width:55%;
}
.element-two .content .italic {
    font-weight: 600;
    font-style: italic;
}
.element-two .content 
{
    text-align:right;
    height:400px;
    width:40%;
}
.element-two .top {
    align-items: flex-end;
}
.element-two .left.content .round-element {
    border:1px solid #fff;
    text-transform: uppercase;
    font-size:18px;
    margin-top:0;
}
.element-two .left.content 
{
    margin-left:5%;
    text-align:left;
}
.element-two .right.content 
{
    margin-right:5%;
    text-align:right;
}
.element-two .top,
.element-two .bottom {
    display:flex;
    padding-left:10%;
    padding-right:10%;
}

.element-two .content {
    color:#fff;
    font-size:60px;
    padding:5%;
    line-height: 0.8;

    background:#000;
}
  
</style>